import React from 'react';
import type { RouteType } from './config';

import { ReactComponent as CalendarIcon } from 'src/ui/assets/images/calendar-link.svg';
import { ReactComponent as CalendarActiveIcon } from 'src/ui/assets/images/calendar-link-active.svg';
import { ReactComponent as ChecklistIcon } from 'src/ui/assets/images/checklist-link.svg';
import { ReactComponent as ChecklistActiveIcon } from 'src/ui/assets/images/checklist-link-active.svg';
import { ReactComponent as FaqIcon } from 'src/ui/assets/images/faq-link.svg';
import { ReactComponent as FAQActiveIcon } from 'src/ui/assets/images/faq-link-active.svg';
import { ReactComponent as LearnIcon } from 'src/ui/assets/images/learn-link.svg';
import { ReactComponent as LearnActiveIcon } from 'src/ui/assets/images/learn-link-active.svg';
import { ReactComponent as ShopIcon } from 'src/ui/assets/images/shop-link.svg';
import { ReactComponent as ShopActiveIcon } from 'src/ui/assets/images/shop-link-active.svg';

const Calendar = React.lazy(() => import('src/ui/pages/Calendar'));
const Faq = React.lazy(() => import('src/ui/pages/Faq'));
const Learn = React.lazy(() => import('src/ui/pages/Learn'));
const Article = React.lazy(() => import('src/ui/pages/Learn/Article'));
const Checklist = React.lazy(() => import('src/ui/pages/Checklist'));
const ChecklistItems = React.lazy(() => import('src/ui/pages/Checklist/Checklist'));
const Shop = React.lazy(() => import('src/ui/pages/Shop/Shop'));
const Products = React.lazy(() => import('src/ui/pages/Shop/Product'));

const appRoutes: RouteType[] = [
  {
    index: true,
    path: '/',
    element: <Calendar />,
    sidebarProps: {
      displayText: 'Calendar',
      icon: <CalendarIcon />,
      iconActive: <CalendarActiveIcon />,
    },
  },
  {
    index: true,
    path: '/learn',
    element: <Learn />,
    sidebarProps: {
      displayText: 'Learn',
      icon: <LearnIcon />,
      iconActive: <LearnActiveIcon />,
    },
  },
  {
    index: false,
    path: '/learn/:id',
    element: <Article />,
    sidebarProps: {
      displayText: 'Article',
      icon: <LearnIcon />,
      iconActive: <LearnActiveIcon />,
    },
  },
  {
    index: true,
    path: '/checklist',
    element: <Checklist />,
    sidebarProps: {
      displayText: 'Checklist',
      icon: <ChecklistIcon />,
      iconActive: <ChecklistActiveIcon />,
    },
  },
  {
    index: false,
    path: '/checklist/:id',
    element: <ChecklistItems />,
    sidebarProps: {
      displayText: 'Checklist',
      icon: <ChecklistIcon />,
      iconActive: <ChecklistActiveIcon />,
    },
  },
  {
    index: true,
    path: '/shop',
    element: <Shop />,
    sidebarProps: {
      displayText: 'Shop',
      icon: <ShopIcon />,
      iconActive: <ShopActiveIcon />,
    },
  },
  {
    index: false,
    path: '/shop/:id',
    element: <Products />,
    sidebarProps: {
      displayText: 'Product',
      icon: <ShopIcon />,
      iconActive: <ShopActiveIcon />,
    },
  },
  {
    index: true,
    path: '/faq',
    element: <Faq />,
    sidebarProps: {
      displayText: 'FAQ',
      icon: <FaqIcon />,
      iconActive: <FAQActiveIcon />,
    },
  },
];

export default appRoutes;
