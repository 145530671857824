import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { ArticleType, LearnCategoryType } from 'src/types';
import { changeOrder } from '../../ui/pages/Learn/thunks';
import { toast } from 'react-toastify';

const getInitialStore = () => ({
  categories: [] as LearnCategoryType[],
  currentArticles: [] as ArticleType[],
});

const learnSlice = createSlice({
  name: 'learn',
  initialState: getInitialStore,
  reducers: {
    addCategory: (store, { payload }: PayloadAction<LearnCategoryType>) => {
      store.categories.push(payload);
    },
    setCategories: (store, { payload }: PayloadAction<{ data: LearnCategoryType[] }>) => {
      store.categories = payload.data;
    },
    removeCategory: (store, { payload }: PayloadAction<number>) => {
      store.categories = store.categories.filter((category) => category.id !== payload);
    },
    updateCategoryTitle: (store, { payload }: PayloadAction<{ id: number; data: string }>) => {
      const res = store.categories.find((category) => category.id === payload.id);
      if (!res) { return; }
      res.title = payload.data;
    },
    updateCategoryImage: (store, { payload }: PayloadAction<{ id: number; data: string }>) => {
      const res = store.categories.find((category) => category.id === payload.id);
      if (!res) { return; }
      res.image = payload.data;
    },

    addArticle: (store, { payload }: PayloadAction<ArticleType>) => {
      store.currentArticles.push(payload);
    },
    setArticles: (store, { payload }: PayloadAction<{ data: ArticleType[] }>) => {
      store.currentArticles = payload.data;
    },
    removeArticle: (store, { payload }: PayloadAction<number>) => {
      store.currentArticles = store.currentArticles.filter((article) => article.id !== payload);
    },

    updateArticle: (store, { payload }: PayloadAction<{ id: number; data: string }>) => {
      const res = store.currentArticles.find((article) => article.id === payload.id);
      if (res && res.title) {
        res.title = payload.data;
      }
    },
    resetArticles: (store) => {
      store.currentArticles = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(changeOrder.fulfilled, (store, { payload }) => {
      store.categories = payload.data.data || [];
    });
    builder.addCase(changeOrder.rejected, () => {
      toast.error('change order error');
    });
  },
});

export const learnSliceActions = learnSlice.actions;

export default learnSlice.reducer;
