import type { AuthPropsType } from 'src/ui/components/Protector';

import { object, string } from 'yup';

export type RouteTextKeyType = 'SignIn' | 'Calendar' | 'Learn' | 'FAQ' | 'Checklist' | 'Shop';

type LinkType = 'internal' | 'external';

const createRoute = <A extends string, B extends AuthPropsType | null, C>(params: {
  path: A;
  auth: B;
  createPath?: C;
  textKey?: RouteTextKeyType;
  type?: LinkType;
}): {
  path: A;
  auth: B;
  createPath: C extends undefined ? null : C;
  type: LinkType;
  linkTitleKey: string;
  pageTitleKey: string;
} => {
  return {
    path: params.path,
    auth: params.auth,
    createPath: (params.createPath || null) as C extends undefined ? null : C,
    type: params.type || 'internal',
    pageTitleKey: params.textKey || '',
    linkTitleKey: params.textKey || '',
  };
};

export const ROUTES = {
  signIn: createRoute({
    path: '/',
    auth: { isAuthRequired: false },
    textKey: 'SignIn',
  }),
  calendar: createRoute({
    path: '/',
    auth: { isAuthRequired: true },
    textKey: 'Calendar',
  }),
  learn: createRoute({
    path: '/learn',
    auth: { isAuthRequired: true },
    textKey: 'Learn',
  }),
  faq: createRoute({
    path: '/faq',
    auth: { isAuthRequired: true },
    textKey: 'FAQ',
  }),
  checklist: createRoute({
    path: '/checklist',
    auth: { isAuthRequired: true },
    textKey: 'Checklist',
  }),
  shop: createRoute({
    path: '/shop',
    auth: { isAuthRequired: true },
    textKey: 'Shop',
  }),

} as const;

export type RouteKeyType = keyof typeof ROUTES;

export const emailValidationSchema = object().shape({
  email: string()
    .required().email(),
  password: string()
    .required(),
});

export const toastMessages = {
  error: {
    networkError: 'Network Error',
    somethingWentWrong: 'Something went wrong',
    incorrectImageSize: "Your image doesn't fit the criteria. Please try another one",
  },
  success: {
    success: 'Successful action',
    Successful: 'Successful',
  },
  warn: {},
};

export const maxFileSize = 2 * 1024 * 1024; // 2MB
