import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import store from 'src/store';

import 'react-toastify/dist/ReactToastify.min.css';
import GlobalStyles from './styles/GlobalStyles';
import './fonts/font.css';
import StyledComponentsThemeProvider from './ui/containers/StyledComponentsThemeProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <StyledComponentsThemeProvider>
        <BrowserRouter>
          <App />
          <GlobalStyles />
        </BrowserRouter>
      </StyledComponentsThemeProvider>
    </Provider>
  </React.StrictMode>,
);
