import React from 'react';
import type { FormikErrors } from 'formik';
import classnames from 'classnames';

import TextField from '@mui/material/TextField';
import StyledCustomInput from 'src/ui/components/CustomInput/CustomInput.styles';

type ChannelInputPropsType = {
  value?: string | number;
  onChangeValue: React.ChangeEventHandler<HTMLInputElement>;
  titleLabel?: string;
  placeholder?: string;
  promptLabel?: string;
  startAdornment?: React.ReactNode;
  multiline?: boolean;
  fullWidth?: boolean;
  customWidth?: string;
  classes?: string;
  inputRef?: React.MutableRefObject<HTMLInputElement | null>;
  error?: FormikErrors<string>;
  autocomplete?: 'on' | 'off';
  endAdornment?: React.ReactNode;
  touched?: boolean;
  errBorder?: boolean;
  textSize?: 'small' | 'medium' | 'large';
  isPassword?: boolean;
  isNumber?: boolean;
  handleKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: () => void;
  setAddButton?: () => void;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const CustomInput: React.FC<ChannelInputPropsType> = (props) => {
  const isLarge = props.textSize === 'large';
  const isMedium = props.textSize === 'medium';
  const isError = Boolean(props.error) && props.touched;

  const handleChange = (el: React.ChangeEvent<HTMLInputElement>) => {
    props.onChangeValue(el);
    if (props.onFocus) {
      props.onFocus();
    }
  };

  return (
    <StyledCustomInput
      fullWidth={props.fullWidth}
      withLabelMargin={!!props.titleLabel}
      customWidth={props.customWidth}
    >
      <TextField
        autoFocus={props.autoFocus}
        value={props.value}
        label={props.titleLabel}
        helperText={(props.error && props.touched) ? props.error : props.promptLabel}
        inputRef={props.inputRef}
        onChange={handleChange}
        onBlur={props.onBlur}
        onKeyDown={props.handleKeyDown}
        placeholder={props.placeholder || ''}
        fullWidth={props.fullWidth}
        multiline={props.multiline}
        error={isError}
        // eslint-disable-next-line no-nested-ternary
        id={props.isPassword ? 'outlined-password-input' : props.isNumber ? 'number' : undefined}
        // eslint-disable-next-line no-nested-ternary
        type={props.isPassword ? 'password' : props.isNumber ? 'number' : undefined}
        autoComplete={props.isPassword ? 'current-password' : undefined}
        margin="dense"
        classes={{
          root: 'custom-input__container',
        }}
        FormHelperTextProps={{
          classes: {
            root: 'custom-input__helper-text',
          },
        }}
        InputLabelProps={{
          shrink: true,
          classes: {
            root: classnames(
              'custom-input__label-top',
              'custom-input__label',
              { 'custom-input__label_lg': isLarge },
            ),
            shrink: classnames(
              'custom-input__label-top',
              { 'custom-input__label-top--error': props.error && props.touched },
              { 'custom-input__label-top_lg': isLarge },
            ),
          },
        }}
        InputProps={{
          endAdornment: props.endAdornment,
          startAdornment: props.startAdornment,
          classes: {
            root: 'custom-input__wrapper',
            input: classnames(
              'custom-input__input',
              props.classes,
              { 'custom-input__input_lg': isLarge },
              { 'custom-input__input_md': isMedium },
            ),
            focused: 'custom-input__focused',
            notchedOutline: classnames(
              'custom-input__outline',
              { 'custom-input__outline--error': props.error && props.touched },
              { 'custom-input__err-border': props.errBorder },
            ),
          },
        }}
        inputProps={{
          min: props.isNumber ? '0' : undefined,
          maxLength: `${props.maxLength || undefined}`,
          autoComplete: props.autocomplete || 'on',
          placeholder: props.placeholder || '',
          name: props.name,
          id: props.name,
        }}
      />
    </StyledCustomInput>
  );
};

export default CustomInput;
