import React from 'react';
import _omit from 'lodash/omit';

import type { ButtonProps } from '@mui/material/Button';
import LottieAnimation from 'src/ui/components/LottieAnimation';
import StyledButton from './Button.styles';

const Button: React.FC<{
  isLoading?: boolean;
  sizeCustom?: 'lg' | 'md';
} & ButtonProps> = (props) => {
  const sizeBtn = props.sizeCustom || 'md';
  return (
    <StyledButton $btnSize={sizeBtn}
      {..._omit(props, ['isLoading', 'size'])}
    >
      {props.isLoading
        ? <LottieAnimation animation="circleLoader" className="button__loader-icon" />
        : props.children
      }
    </StyledButton>
  );
};

export default Button;
