/* eslint-disable max-len */
import type { ChecklistCategoryType, ChecklistItemType } from 'src/types';
import http from './http';

import type { ResponseType } from './http';

const checklists = '/api/admin/checklists';

const createCategory = async (data: ChecklistCategoryType) => {
  const response = await http.post<ResponseType<ChecklistCategoryType>>(`${checklists}/categories`, data);
  return response;
};

const getCategories = async () => {
  const response = await http.get<ResponseType<{ data: ChecklistCategoryType[] }>>(`${checklists}/categories`);
  return response;
};

const updateCategory = async (id: number, data: ChecklistCategoryType) => {
  const response = await http.post(`${checklists}/categories/${id}`, data);
  return response;
};

const updateCategoryTags = async (id: number, data: { refTagsIds: number[] }) => {
  const response = await http.post(`${checklists}/categories/${id}/tags`, data);
  return response;
};

const deleteCategory = async (id: number) => {
  const response = await http.delete(`${checklists}/categories/${id}`);
  return response;
};

const getItems = async (id: number) => {
  const response = await http.get<ResponseType<{ data: ChecklistItemType[] }>>(`${checklists}/categories/${id}/items`);
  return response;
};

const createItem = async (id: number, data: ChecklistItemType) => {
  const response = await http.post<ResponseType<ChecklistItemType>>(`${checklists}/categories/${id}/items`, data);
  return response;
};

const updateItemOrder = async (id: number, data: { checklistItems: { id: number; order: number }[] }) => {
  const response = await http.post<ResponseType<ChecklistItemType>>(`${checklists}/categories/${id}/items/order`, data);
  return response;
};

const updateCategoryOrder = async (data: { checklist: { id: number; order: number }[] }) => {
  const response = await http.patch<ResponseType<ChecklistCategoryType[]>>(`${checklists}/categories/order`, data);
  return response;
};

const updateItem = async (id: number, data: { text: string }) => {
  const response = await http.post(`${checklists}/categories/items/${id}`, data);
  return response;
};

const deleteItem = async (id: number) => {
  const response = await http.delete(`${checklists}/categories/items/${id}`);
  return response;
};

export default {
  createCategory,
  getCategories,
  updateCategory,
  updateCategoryTags,
  deleteCategory,
  getItems,
  createItem,
  updateItemOrder,
  updateItem,
  deleteItem,
  updateCategoryOrder,
};
