import styled from 'styled-components';

export default styled.aside`
  display: flex;
  width: 300px;
  height: 100%;
  min-height: 100%;
  flex-direction: column;
  padding-top: 0px;
  padding-bottom: 0px;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.palette.background.sidebar};
  border: 1px solid ${({ theme }) => theme.palette.divider};

   .top-main_container {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .left-sidebar__logo-image {
    vertical-align: top;
    margin-bottom: 40px;
  }
  .left-sidebar__user-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .logout-button {
    &:hover {
      border-radius: 2;
    }
    padding: 14px 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    width: 240px;
    color: ${({ theme }) => theme.palette.text.gray};
    font-weight: ${({ theme }) => theme.font.weight.xs};
    font-size: ${({ theme }) => theme.font.size.lg};
    background-color: unset;
  }
`;
