import React from 'react';

import Typography from '@mui/material/Typography';
import StyledPageContainer from 'src/ui/components/PageContainer/PageContainer.styles';

import { useLocation } from 'react-router-dom';

const PageContainer: React.FC<React.PropsWithChildren & {
  className?: string;
  hideTitle?: boolean;
  containerRef?: React.RefObject<HTMLDivElement>;
}> = (props) => {
  const location = useLocation();

  const title = React.useMemo(() => {
    if (props.hideTitle) {
      return null;
    }
    if (!location.pathname) {
      return null;
    }
    if (location.pathname === '/') {
      return 'CALENDAR';
    }
    if (location.pathname?.split('/')?.pop() && Number(location.pathname?.split('/')?.pop())) {
      return null;
    }
    const title = location.pathname?.split('/')?.pop()?.toUpperCase() || '';
    return title;
  }, [location.pathname, props.hideTitle]);

  return (
    <StyledPageContainer ref={props?.containerRef} className={props.className}>
      {title && !props.hideTitle && (
        <Typography variant="h1" className="page-title">
          {title}
        </Typography>
      )}

      {props.children}
    </StyledPageContainer>
  );
};

export default PageContainer;
