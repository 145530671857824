import { Link, useLocation } from 'react-router-dom';

import ListItemButton from '@mui/material/ListItemButton';

import StyledSidebarItem from './SidebarItem.styles';

import type { RouteType } from 'src/ui/components/routes/config';
import React from 'react';

type SidebarItemPropsType = {
  item: RouteType;
};

const SidebarItem: React.FC<SidebarItemPropsType> = ({ item }) => {
  const location = useLocation();

  const isCurrentAppState = React.useMemo(() => {
    if (!item.path) {
      return false;
    }

    if (item.path === '/') {
      return location.pathname === item.path;
    }

    return location.pathname.includes(item.path);
  }, [location.pathname, item.path]);

  return (
    <StyledSidebarItem isCurrentAppState={isCurrentAppState}>
      {item.sidebarProps && item.path ? (
        <ListItemButton
          component={Link}
          to={item.path}
          classes={{
            root: 'list-item-button',
          }}
        >
          {isCurrentAppState ? item.sidebarProps.iconActive : item.sidebarProps.icon}
          {item.sidebarProps.displayText}
        </ListItemButton>
      ) : null}
    </StyledSidebarItem>
  );
};

export default SidebarItem;
