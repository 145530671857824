import type { ListProps } from '@mui/material/List';
import List from '@mui/material/List';
import styled from 'styled-components';

export default styled(List)<ListProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;
