import React, { useEffect, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import Toastify from 'src/ui/containers/Toastify';

import IconButton from '@mui/material/IconButton';

import { ReactComponent as CloseIcon } from 'src/ui/assets/images/close-icon.svg';

import Loader from 'src/ui/components/Loader';
import SignInPage from 'src/ui/pages/auth/SignInPage/SignInPage';
import MainLayout from './ui/components/Layout/MainLayout';

import appRoutes from './ui/components/routes/appRoutes';

import storage from 'src/utils/storage';
import { useAppSelector, useAppDispatch } from './store/store';
import mainSliceThunks from './store/mainSlice/mainSlice.thunks';

function App() {
  const dispatch = useAppDispatch();
  const user = useAppSelector(({ main }) => main.user);

  const token = storage.authToken.get();

  useEffect(() => {
    if (!token) { return; }
    dispatch(mainSliceThunks.authorize());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = useMemo(() => !!token && !user?.email, [token, user]);

  const isAuthorized = useMemo(() => !!token && !!user?.email, [token, user]);

  if (isLoading) {
    return <Loader isFixed />;
  }

  return (
    <React.Suspense fallback={<Loader isFixed />}>
      <Toastify
        autoClose={3000}
        draggable
        newestOnTop
        hideProgressBar
        closeButton={({ closeToast }) => (
          <IconButton onClick={closeToast}>
            <CloseIcon />
          </IconButton>
        )}
      />
      <Routes>
        {isAuthorized
          ? (<Route path="/" element={<MainLayout />}>
              {appRoutes.map((route, index) => (
                <Route
                  index={!!route.index}
                  path={route.path}
                  element={route.element}
                  key={index}
                />
              ))}
             </Route>)
          : (<Route path="/" element={<SignInPage />} />)}
      </Routes>
    </React.Suspense>
  );
}

export default App;
