import styled, { css } from 'styled-components';

import LottieAnimation from '../LottieAnimation';

const LOADER_SIZE = 150;

const fixedStyles = css`
  position: fixed;
  top: calc(50% - ${LOADER_SIZE / 2}px);
  left: calc(50% - ${LOADER_SIZE / 2}px);
`;

export default styled(LottieAnimation) <{ isFixed: boolean }>`
  ${({ isFixed }) => (isFixed ? fixedStyles : '')}
  height: ${LOADER_SIZE}px;
  width: ${LOADER_SIZE}px;
`;
