import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import StyledUserBar, { AvatarBox } from 'src/ui/components/UserBar/UserBar.styles';

import type { IUser } from 'src/types';

const UserBar: React.FC<{
  user?: IUser;
}> = (props) => {
  const userName = props.user?.profileName || 'Admin';

  return (
    <StyledUserBar>
      <div className="content-container">
        <Tooltip title={userName}>
          <AvatarBox
            variant="rounded"
          >
            <p className="user-name">{userName[0]}</p>
          </AvatarBox>
        </Tooltip>
        <div className="title-container">
          {userName}
        </div>
      </div>
    </StyledUserBar>
  );
};

export default UserBar;
