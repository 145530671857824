import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { ShopItemType, ShopCategoryType } from 'src/types';
import { changeOrder } from '../../ui/pages/Shop/thunk';
import { toast } from 'react-toastify';

const getInitialStore = () => ({
  categories: [] as ShopCategoryType[],
  currentShopItems: [] as ShopItemType[],
});

const shopSlice = createSlice({
  name: 'ShopList',
  initialState: getInitialStore,
  reducers: {
    addCategory: (store, { payload }: PayloadAction<ShopCategoryType>) => {
      store.categories.push(payload);
    },
    setCategories: (store, { payload }: PayloadAction<{ data: ShopCategoryType[] }>) => {
      store.categories = payload.data;
    },
    removeCategory: (store, { payload }: PayloadAction<number>) => {
      store.categories = store.categories.filter((category) => category.id !== payload);
    },
    updateCategoryTitle: (store, { payload }: PayloadAction<{ id: number; data: string }>) => {
      const res = store.categories.find((category) => category.id === payload.id);
      if (res && res.title) {
        res.title = payload.data;
      }
    },
    updateCategoryImage: (store, { payload }: PayloadAction<{ id: number; data: string }>) => {
      const res = store.categories.find((category) => category.id === payload.id);
      if (res) {
        res.image = payload.data;
      }
    },

    addShopListItem: (
      store,
      { payload }: PayloadAction<{ id: number; shopListItem: ShopItemType }>,
    ) => {
      const currentCategory = store.categories.find((category) => category.id === payload.id);
      if (!currentCategory) {
        return;
      }
      if (currentCategory.items) {
        currentCategory.items.push(payload.shopListItem);
      } else {
        currentCategory.items = [payload.shopListItem];
      }
    },
    setShopListItems: (
      store,
      { payload }: PayloadAction<{ id: number; ShopList: ShopItemType[] }>,
    ) => {
      store.categories = store.categories.map((category) => {
        if (category.id === payload.id) {
          return {
            ...category,
            items: payload.ShopList,
          };
        }
        return category;
      });
    },
    removeShopListItem: (
      store,
      { payload }: PayloadAction<{ id: number; shopListItemId: number }>,
    ) => {
      store.categories = store.categories.map((category) => {
        if (category.id === payload.id) {
          return {
            ...category,
            items: category.items?.filter(
              (item) => item.id !== payload.shopListItemId,
            ),
          };
        }
        return category;
      });
    },
    updateShopListItem: (
      store,
      { payload }: PayloadAction<{ id: number; data: ShopItemType }>,
    ) => {
      store.categories = store.categories.map((category) => {
        if (category.id !== payload.id) {
          return category;
        }

        const currentItems = category.items?.filter((item) => item.id === payload.data.id) ?? [];
        currentItems.push(payload.data);

        return {
          ...category,
          items: currentItems,
        };
      });
    },
    resetShopList: (store) => {
      store.categories = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(changeOrder.fulfilled, (store, { payload }) => {
      store.categories = payload.data || [];
    });
    builder.addCase(changeOrder.rejected, () => {
      toast.error('change order error');
    });
  },
});

export const shopSliceActions = shopSlice.actions;

export default shopSlice.reducer;
