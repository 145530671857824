import { createAsyncThunk } from '@reduxjs/toolkit';
import type { IndexDataType } from '../Container';
import checklistApi from '../../../../api/checklistApi';

export const changeOrder = createAsyncThunk(
  'checklist/changeOrder',
  async (data: IndexDataType[]) => {
    const response = await checklistApi.updateCategoryOrder({
      checklist: data,
    });
    return response;
  },
);
