import styled from 'styled-components';

import AuthPageContainer from 'src/ui/components/AuthPageContainer';

export default styled(AuthPageContainer)`
  .form-container {
    width: 100%;
    max-width: 410px;
    box-shadow: none;
  }
  
  .form-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .sign-in__title {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.lg};
    font-size: ${({ theme }) => theme.font.size.xlgSuper};
  }
  
  .sign-in__wrapper {
    padding: 32px 6px 0px 24px;
    min-width: fit-content;
  }
  
  .sign-in__wrapper--without-border-bottom {
    border-bottom: none;
    border-radius: 12px 12px 0px 0px;
  }

  .sign-in__form {
    padding: 0px 24px;
  }

  .sign-in__contained-button {
    align-self: flex-end;
    width: fit-content;
  }

  .sign-in__domain-name {
    font-weight: ${({ theme }) => theme.font.weight.xlg};
  }

  .sign-in__text {
    font-weight: ${({ theme }) => theme.font.weight.sm};
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .sign-in__link {
    font-weight: ${({ theme }) => theme.font.weight.sm};
    font-size: ${({ theme }) => theme.font.size.sm};
    color: ${({ theme }) => theme.palette.primary[500]};
  }

  .domain__prompt {
    color: ${({ theme }) => theme.palette.primary[400]};
  }

  .sign-in__accounts {
    display: flex;
    flex-direction: column;
    max-height: 330px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 24px;
    width: 100%;
  }

  .sign-in__accounts::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  .sign-in__accounts::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${({ theme }) => theme.palette.divider};
  }

  .sign-in__accounts-title {
    margin-bottom: 36px;
    width: 346px;
  }

  .sign-in__accounts-footer {
    padding: 16px 24px 32px;
    display: flex;
    justify-content: end;
    border-radius: 0px 0px 12px 12px;
    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-top: none;
  }
  
  .sign-in__accounts-footer--with-box-shadow {
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.05);
  }

  .input__wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 28px;
    margin-bottom: 20px;
  }
  .sign-in__submit-button {
    height: 51px;
  }

  .sign-in__iconButton {
    margin-right: 20px;
  }
`;
