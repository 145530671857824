import Avatar from '@mui/material/Avatar';
import styled from 'styled-components';

export default styled.div`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: 14px;
  width: 240px;
  height: 98px;

  .content-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  .title-container {
    width: 130px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: ${({ theme }) => theme.font.size.mdExt};
    font-weight: ${({ theme }) => theme.font.weight.xs};
    color: ${({ theme }) => theme.palette.primary[600]};
  }

  .user-name {
    text-transform: uppercase;
  }
`;

export const AvatarBox = styled(Avatar)`
  width: 50px;
  height: 50px;
  background-color: ${({ theme }) => theme.palette.background.greenLight};
  color: ${({ theme }) => theme.palette.text.gray};
`;
