import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import thunks from './mainSlice.thunks';

import type { IUser } from 'src/types';

const getInitialStore = () => ({
  user: null as IUser | null,
});

const mainSlice = createSlice({
  name: 'main',
  initialState: getInitialStore,
  reducers: {
    setUser: (store, { payload }: PayloadAction<IUser | null>) => {
      store.user = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(thunks.authorize.fulfilled, (store, { payload }) => {
      store.user = payload;
    });
    builder.addCase(thunks.authorize.rejected, (store) => {
      store.user = null;
    });
  },

});

export const mainSliceActions = mainSlice.actions;

export default mainSlice.reducer;
