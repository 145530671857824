import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import shopApi from 'src/api/shopApi';
import { shopSliceActions } from 'src/store/shopSlice/shopSlice.reducer';
import type { IndexDataType } from '../../Checklist/Container';

export const loadProducts = createAsyncThunk(
  'shop/getProducts',
  async (categoryId: number, { dispatch }) => {
    try {
      if (!categoryId) {
        return;
      }
      const productsList = await shopApi.getShopItems(categoryId);
      if (productsList.data?.data) {
        dispatch(
          shopSliceActions.setShopListItems({
            id: Number(categoryId),
            ShopList: productsList.data.data,
          }),
        );
      }
    } catch (err) {
      toast.error('load products error');
    }
  },
);
export const changeOrder = createAsyncThunk(
  'shop/changeOrder',
  async (data: IndexDataType[]) => {
    const response = await shopApi.updateCategoryOrder({ shop: data });
    return response;
  },
);
