import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { CommonTextInfoType, FaqItemType } from 'src/types';

const getDefaultValues = () => ({ title: '', text: '' });

const getInitialStore = () => ({
  aboutUs: getDefaultValues(),
  terms: getDefaultValues(),
  privacyPolicy: getDefaultValues(),
  faqItems: [] as FaqItemType[],
});

const faqSlice = createSlice({
  name: 'faq',
  initialState: getInitialStore,
  reducers: {
    setAboutUs: (store, { payload }: PayloadAction<CommonTextInfoType>) => {
      store.aboutUs = payload;
    },
    removeAboutUs: (store) => {
      store.aboutUs = getDefaultValues();
    },
    setTerms: (store, { payload }: PayloadAction<CommonTextInfoType>) => {
      store.terms = payload;
    },
    removeTerms: (store) => {
      store.terms = getDefaultValues();
    },

    setPrivacyPolicy: (store, { payload }: PayloadAction<CommonTextInfoType>) => {
      store.privacyPolicy = payload;
    },
    removePrivacyPolicy: (store) => {
      store.privacyPolicy = getDefaultValues();
    },

    addFaqItems: (store, { payload }: PayloadAction<FaqItemType>) => {
      store.faqItems.push(payload);
    },
    setFaqItems: (store, { payload }: PayloadAction<{ data: FaqItemType[] }>) => {
      store.faqItems = payload.data;
    },
    resetChecklists: () => {
      return getInitialStore();
    },
  },

});

export const faqSliceActions = faqSlice.actions;

export default faqSlice.reducer;
