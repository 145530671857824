import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { IShortWeek } from 'src/types';

const getInitialStore = () => ({
  pregnancyWeeks: [] as IShortWeek[],
  postPartumWeeks: [] as IShortWeek[],
});

const calendarSlice = createSlice({
  name: 'calendar',
  initialState: getInitialStore,
  reducers: {
    addPregnancyWeek: (store, { payload }: PayloadAction<IShortWeek>) => {
      store.pregnancyWeeks.push(payload);
    },
    addPostPartumWeek: (store, { payload }: PayloadAction<IShortWeek>) => {
      store.postPartumWeeks.push(payload);
    },
    setPregnancyWeeks: (store, { payload }: PayloadAction<{ data: IShortWeek[] }>) => {
      store.pregnancyWeeks = payload.data;
    },
    setPostPartumWeeks: (store, { payload }: PayloadAction<{ data:IShortWeek[] }>) => {
      store.postPartumWeeks = payload.data;
    },
  },

});

export const calendarSliceActions = calendarSlice.actions;

export default calendarSlice.reducer;
