import { combineReducers } from 'redux';

import mainSlice from 'src/store/mainSlice';
import calendarSlice from 'src/store/calendarSlice';
import learnSlice from 'src/store/learnSlice';
import checklistSlice from 'src/store/checklistSlice';
import shopSlice from 'src/store/shopSlice';
import faqSlice from 'src/store/faqSlice';

const combinedReducer = combineReducers({
  main: mainSlice,
  calendar: calendarSlice,
  learn: learnSlice,
  checklist: checklistSlice,
  shop: shopSlice,
  faq: faqSlice,
});

export const RESET_STORE_ACTION_TYPE = 'RESET_WHOLE_STORE';

const rootReducer = (
  state: ReturnType<typeof combinedReducer> | undefined,
  action: { type: string; payload: unknown },
) => {
  if (action.type === RESET_STORE_ACTION_TYPE) {
    return combinedReducer(undefined, action);
  }

  return combinedReducer(state, action);
};

export default rootReducer;
