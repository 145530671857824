import styled from 'styled-components';

type StyledPropsType = {
  fullWidth?: boolean;
  customWidth?: string;
  withLabelMargin?: boolean;
};

const StyledCustomInput = styled.div<StyledPropsType>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  width: ${({ customWidth }) => (customWidth || undefined)};

  .custom-input__label {
    font-size: ${({ theme }) => theme.font.size.mdLight};
    font-weight: ${({ theme }) => theme.font.weight.xxs};
  }

  .custom-input__label_lg {
    font-size: ${({ theme }) => theme.font.size.md};
  }

  .custom-input__input {
    flex: 1 1 auto;
    width: ${({ customWidth }) => (customWidth ? undefined : 'fit-content')};
    padding: 10.5px 20px;
    &::placeholder {
      color: ${({ theme }) => theme.palette.text.textGreyLight};
    }
  }

  .custom-input__input_lg {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 48px;
  }

  .custom-input__input_lg {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 40px;
  }

  .custom-input__label-top {
    left: 0px;
    padding: 0px;
    transform: translate(0px, -23px);
    color: ${({ theme }) => theme.palette.primary[600]};

  }

  .custom-input__label-top_lg {
    transform: translate(10px, -12px) scale(0.7);
  }

  .custom-input__label-top--error {
    // color: ${({ theme }) => theme.palette.error.dark};
  }

  .custom-input__container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border: none;
    margin-top: ${({ withLabelMargin }) => (withLabelMargin ? '20px' : '0px')};
    margin-bottom: 0px;
  }

  .custom-input__wrapper {
    border: none;
    display: flex;
    flex-wrap: nowrap;
    padding-right: 0;
    background: ${({ theme }) => theme.palette.input.background};
    border-radius: 14px;
  }

  .custom-input__outline.custom-input__outline {
    border: 1.5px solid ${({ theme }) => theme.palette.input.text.light};
    border-radius: 14px;
    top: 0px;

    & > legend {
      display: none;
    }
  }

  .custom-input__outline--error {
    border: 1px solid ${({ theme }) => theme.palette.error.main} !important;
  }

  .custom-input__err-border {
    border: 1px solid ${({ theme }) => theme.palette.error.main} !important;
  }

  .custom-input__focused {
    border: none;
    .custom-input__outline--error {
      border: 1px solid ${({ theme }) => theme.palette.error.main} !important;
    }
  }

  .custom-input__adornment, .custom-input__adornment {
    font-size: ${({ theme }) => theme.font.size.xs};
    display: flex;
  }

  .custom-input__input {
    font-size: ${({ theme }) => theme.font.size.mdExt};
    font-weight: ${({ theme }) => theme.font.weight.xxs};
    font-family: ${({ theme }) => theme.font.family.main};
  }

  .custom-input__helper-text {
    font-size: ${({ theme }) => theme.font.size.xsLight};
    font-weight: ${({ theme }) => theme.font.weight.xs};
    margin: 0px;
    margin-top: 8px;
  }

  .custom-input__counter {
    color: ${({ theme }) => theme.palette.primary[400]};
  }

  .domain-adornment {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 11px;
    padding-right: 11px;
    font-weight: ${({ theme }) => theme.font.weight.sm};
    font-size: ${({ theme }) => theme.font.size.sm};
    border-left: 1px solid ${({ theme }) => theme.palette.divider};
    height: 40px;
    color: ${({ theme }) => theme.palette.grey[500]};
    background-color: ${({ theme }) => theme.palette.background.paper};
  }
`;

export default StyledCustomInput;
