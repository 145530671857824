import http, { axiosWithoutAuth, updateToken } from './http';
import type { IUser } from 'src/types';
import type { ResponseType } from './http';

import helpers from 'src/utils/helpers';

const authPath = '/api/admin/auth';

type SignInTokensPairType = { token: string; refreshToken: string };
type SignInPayloadType = { userData: IUser } & SignInTokensPairType;

const signIn = async (data: { email: string; password: string }) => {
  const response = await axiosWithoutAuth.post<ResponseType<SignInPayloadType>>(`${authPath}/sign-in`, data);
  helpers.setTokens(response.data.token, response.data.refreshToken);
  updateToken();
  return response;
};

const getMe = () => {
  return http.get<ResponseType<IUser>>(`${authPath}/user-data`);
};

const getTokens = async (data: { refreshToken: string }) => {
  const response = await http.post<ResponseType<SignInPayloadType>>(`${authPath}/refresh-token`, data);
  helpers.setTokens(response.data.token, response.data.refreshToken);
  updateToken();
  return response;
};

export default {
  signIn,
  getMe,
  getTokens,
};
