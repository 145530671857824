import styled from 'styled-components';

export default styled.main`
  padding: 30px 40px;
  flex-grow: 1;
  overflow-Y: scroll;
  overflow-X: hidden;
  width: 100%;

  .page-title {
    margin-bottom: 20px;
    font-size: ${({ theme }) => theme.font.size.lg};
    font-weight: ${({ theme }) => theme.font.size.lg};
    font-family: ${({ theme }) => theme.font.family.secondary};
  }

  @media (max-width: ${({ theme }) => (theme.breakpoints?.down ? theme.breakpoints?.down('sm') : '425px')} ) {
    padding: 15px;
  }

  .content-pages_container {
    width: 100%;
  }
`;
