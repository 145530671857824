/* eslint-disable max-len */
import type { ShopCategoryType, ShopItemType } from 'src/types';
import http from './http';

import type { ResponseType } from './http';

const shop = '/api/admin/shop';

const createCategory = async (data: ShopCategoryType) => {
  const response = await http.post<ResponseType<ShopCategoryType>>(`${shop}/categories`, data);
  return response;
};

const getCategories = async () => {
  const response = await http.get<ResponseType<{ data: ShopCategoryType[] }>>(`${shop}/categories`);
  return response;
};

const updateCategory = async (id: number, data: ShopCategoryType) => {
  const response = await http.post(`${shop}/categories/${id}`, data);
  return response;
};

const deleteCategory = async (id: number) => {
  const response = await http.delete(`${shop}/categories/${id}`);
  return response;
};

const updateItemOrder = async (
  id: number,
  data: { shopItems: { id: number; order: number }[] },
) => {
  const response = await http.patch(
    `${shop}/categories/${id}/items/order`,
    data,
  );
  return response;
};

const updateCategoryOrder = async (data: {
  shop: { id: number; order: number }[];
}) => {
  const response = await http.patch<ResponseType<ShopItemType[]>>(`${shop}/categories/order`, data);
  return response;
};

const createShopItem = async (id: number, data: ShopItemType) => {
  const response = await http.post<ResponseType<ShopItemType>>(`${shop}/categories/${id}/items`, data);
  return response;
};

const getShopItems = async (id: number) => {
  const response = await http.get<ResponseType<{ data: ShopItemType[] }>>(`${shop}/categories/${id}/items`);
  return response;
};

const getShopItem = async (id: number) => {
  const response = await http.get<ResponseType<{ data: ShopItemType[] }>>(`${shop}/categories/items/${id}`);
  return response;
};

const updateShopItem = async (id: number, data: ShopItemType) => {
  const response = await http.post(`${shop}/categories/items/${id}`, data);
  return response;
};

const deleteShopItem = async (id: number) => {
  const response = await http.delete(`${shop}/categories/items/${id}`);
  return response;
};

export default {
  createCategory,
  getCategories,
  updateCategory,
  deleteCategory,
  createShopItem,
  getShopItems,
  getShopItem,
  updateShopItem,
  deleteShopItem,
  updateCategoryOrder,
  updateItemOrder,
};
