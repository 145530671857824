import React from 'react';

import StyledLoader from './Loader.styles';

const Loader: React.FC<{
  isFixed?: boolean;
}> = (props) => {
  return (
    <StyledLoader
      isFixed={props.isFixed || false}
      animation="circleLoader"
    />
  );
};

export default Loader;
