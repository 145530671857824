import { createAsyncThunk } from '@reduxjs/toolkit';

import type { IUser } from 'src/types';
import storage from 'src/utils/storage';
import authApi from 'src/api/authApi';

const authorize = createAsyncThunk<IUser | null>(
  'main/authorizeUser',
  async () => {
    const storedAccessToken = storage.authToken.get();
    if (!storedAccessToken) {
      return null;
    }

    const user = await authApi.getMe();

    return user.data;
  },
);

export default {
  authorize,
};
