import React from 'react';

import SidebarItem from './SidebarItem';

import StyledLinkList from './LinkList.styles';

import appRoutes from 'src/ui/components/routes/appRoutes';

const LinksList: React.FC = () => {
  return (
    <StyledLinkList disablePadding>
      {appRoutes.map((route, index) => (
        route.sidebarProps && route.index ? (
          <SidebarItem item={route} key={index} />
        ) : null
      ))}
    </StyledLinkList>
  );
};

export default LinksList;
