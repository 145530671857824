/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useFormik } from 'formik';

import { toast } from 'react-toastify';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { ReactComponent as Logo } from 'src/ui/assets/images/Logo.svg';
import { ReactComponent as EyeOpen } from 'src/ui/assets/images/EyeOpen.svg';
import { ReactComponent as EyeClose } from 'src/ui/assets/images/EyeClosed.svg';
import Button from 'src/ui/components/Button/Button';
import CustomInput from 'src/ui/components/CustomInput/CustomInput';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import StyledSignInPage from 'src/ui/pages/auth/SignInPage/SignInPage.styles';

import { useAppDispatch } from 'src/store';
import authApi from 'src/api/authApi';
import { mainSliceActions } from 'src/store/mainSlice/mainSlice.reducer';
import { emailValidationSchema, toastMessages } from 'src/utils/constants';
import helpers from 'src/utils/helpers';
import { checkError } from 'src/utils/checkError';

const SignInPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const [errorResponse, setErrorResponse] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: emailValidationSchema,
    onSubmit: async () => {
      try {
        setLoading(true);
        formik.setFieldError('password', undefined);
        setErrorResponse(false);
        const responseAuth = await authApi.signIn({
          email: formik.values.email,
          password: formik.values.password,
        });

        dispatch(mainSliceActions.setUser(responseAuth.data.userData));

        helpers.setTokens(responseAuth.data.token, responseAuth.data.refreshToken);

        await formik.setValues(formik.initialValues);
      } catch (err: any) {
        toast.error(toastMessages.error.somethingWentWrong);
        const isBadGatewayError = checkError.isBadGatewayError(err);
        if (isBadGatewayError) {
          formik.setFieldError('password', 'Connection error');
        } else {
          formik.setFieldError('password', 'Your email or password may not be entered correctly. Try again.');
        }
      } finally {
        setErrorResponse(true);
        setLoading(false);
      }
    },
  });

  const hasErrorBorder = errorResponse && !!formik.errors.password;

  const setEmailHandler = (ev: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue('email', ev.target.value);

  const setPasswordHandler = (ev: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue('password', ev.target.value);

  const VisibilityIcon = () => (
    <InputAdornment position="end">
      <IconButton
        className="sign-in__iconButton"
        onClick={handleClickShowPassword}
      >
        {showPassword ? <EyeOpen /> : <EyeClose />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <StyledSignInPage>
      <Box display="flex" justifyContent="center" marginBottom="40px">
        <Logo />
      </Box>

      <Box display="flex" justifyContent="center">
          <Typography variant="h2" className="sign-in__title">
            SIGN IN
          </Typography>
      </Box>

      <form onSubmit={formik.handleSubmit} className="sign-in__form">

        <div className="input__wrapper">
          <CustomInput
            autoFocus
            fullWidth
            titleLabel="Email"
            placeholder="Enter Email"
            value={formik.values.email}
            onChangeValue={setEmailHandler}
            error={formik.errors.email}
            touched={formik.touched.email}
            onBlur={formik.handleBlur}
            onFocus={() => formik.setFieldTouched('email', true, false)}
            errBorder={hasErrorBorder}
          />
          <CustomInput
            fullWidth
            titleLabel="Password"
            placeholder="Enter Password"
            value={formik.values.password}
            onChangeValue={setPasswordHandler}
            error={formik.errors.password}
            touched={formik.touched.password}
            onBlur={formik.handleBlur}
            onFocus={() => formik.setFieldTouched('password', true, false)}
            isPassword={!showPassword}
            endAdornment={<VisibilityIcon />}
          />
        </div>
        <Button
          variant="contained"
          type="submit"
          className="sign-in__submit-button"
          sizeCustom="lg"
          fullWidth
          isLoading={loading}
        >
          Sign In
        </Button>
      </form>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap="12px"
       />
    </StyledSignInPage>
  );
};

export default SignInPage;
