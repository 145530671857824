import Button from '@mui/material/Button';

import { ReactComponent as Logo } from 'src/ui/assets/images/Logo.svg';
import { ReactComponent as Logout } from 'src/ui/assets/images/logout.svg';

import UserBar from '../UserBar/UserBar';

import SidebarStyles from './Sidebar.styles';

import { useAppSelector } from 'src/store/store';
import helpers from 'src/utils/helpers';
import LinksList from './LinksList';

const Sidebar = () => {
  const { user } = useAppSelector(({ main }) => main);

  if (!user) {
    return null;
  }

  return (
    <SidebarStyles>
      <div className="top-main_container">
        <div className="left-sidebar__logo-image">
            <Logo />
        </div>

        <div className="left-sidebar__user-container">
          <UserBar user={user} />
        </div>

        <LinksList />

      </div>
      <Button
        onClick={helpers.logOut}
        classes={{
          root: 'logout-button',
        }}
      >
        <Logout />
        Log out
      </Button>
    </SidebarStyles>
  );
};

export default Sidebar;
