import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import StyledAuthPageContainer from 'src/ui/components/AuthPageContainer/AuthPageContainer.styles';

import { ReactComponent as MobileLogo } from 'src/ui/assets/images/mobile-logo.svg';
import { ReactComponent as LogoLines } from 'src/ui/assets/images/logo-lines.svg';
import { ReactComponent as LogoStar } from 'src/ui/assets/images/logo-star.svg';

const AuthPageContainer: React.FC<{
  className?: string;
  isSignUp?: boolean;
  withBackButton?: boolean;
  onClickBackButton?: () => void;
} & React.PropsWithChildren> = (props) => {
  return (
    <StyledAuthPageContainer className={props.className} isSignUp={props.isSignUp} hideTitle>
      <div className="auth__content">
        <Card className="form-container">
          <CardContent className="form-content">
            {props.children}
          </CardContent>
        </Card>
      </div>
      <div className="auth__promo">
        <LogoStar className="logo-star" />
        <LogoLines className="logo-lines" />
        <MobileLogo className="mobile-logo" />
      </div>
    </StyledAuthPageContainer>
  );
};

export default AuthPageContainer;
