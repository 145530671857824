import type { ThemeOptions, PaletteColor } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import type { PaletteMode } from '@mui/material';

export const MAIN_FONT_SIZE = 16;
export const MAIN_LINE_HEIGHT = 26;

export const lightThemeObject = {
  colors: {
    default: '#000000',
  },
  screen: {
    xs: 0,
    sm: 425,
    md: 768,
    lg: 1024,
    xl: 1440,
  } as const,
  font: {
    family: {
      main: 'Mulish',
      secondary: 'CarlaSansRegular',
    },
    weight: {
      xxs: 300,
      xs: 400,
      sm: 500,
      md: 600,
      lg: 700,
      xlg: 800,
    },
    size: {
      xsLight: '13px',
      xs: '0.75rem',
      sm: '0.875rem',
      mdLight: '15px',
      md: '16px',
      mdExt: '17px',
      lg: '1.125rem',
      xlg: '1.25rem',
      xlgExtra: '23px',
      xlgSuper: '24px',
    },
  },
  transition: {
    0: '0ms',
    1: '100ms',
    2: '300ms',
    3: '500ms',
    4: '1000ms',
  },
  round: {
    0: '0',
    1: '5px',
    2: '15px',
    3: '30px',
  },
};

interface IPalette {
  mode: PaletteMode;
  common: {
    black: string;
    white: string;
  };
  primary: {
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    mainGradient: string;
    hoverGradient: string;
    contrastText: string;
    primaryIvory: string;
  };
  secondary: PaletteColor;
  tertiary: {
    primary: string;
  };
  status: {
    icon: {
      active: string;
      registered: string;
      disabled: string;
    };
    background: {
      active: string;
      registered: string;
      disabled: string;
    };
    text: {
      active: string;
      registered: string;
      disabled: string;
    };
  };
  error: PaletteColor;
  warning: PaletteColor;
  info: PaletteColor;
  success: PaletteColor;
  grey: {
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    A100: string;
    A200: string;
    A300: string;
    A400: string;
  };
  white: {
    A1: string;
    A2: string;
    A3: string;
    A4: string;
    A5: string;
  };
  contrastThreshold: number;
  tonalOffset: number;
  text: {
    disabled: string;
    icon: string;
    primary: string;
    secondary: string;
    tertiary: string;
    quaternary: string;
    //
    gray: string;
    textGreyLight: string;

  };
  divider: string;
  background: {
    paper: string;
    default: string;
    hover: string;
    header: string;
    footerMenu: string;
    //
    sidebar: string;
    secondaryRed: string;
    secondaryRedLight: string;
    secondaryApricot: string;
    secondaryApricotLight: string;
    secondaryMint: string;
    secondaryFertile: string;
    secondaryBlue: string;
    secondaryBlueLight: string;
    dark: string;
    greenLight: string;
    lightIvoryDark: string;
    backgroundGreyLight: string;
    backgroundGreyIvoryLight: string;
    //
  };
  action: {
    active: string;
    hover: string;
    hoverOpacity: number;
    selected: string;
    selectedOpacity: number;
    disabled: string;
    disabledBackground: string;
    disabledBorder: string;
    disabledOpacity: number;
    focus: string;
    focusOpacity: number;
    activatedOpacity: number;
  };
  navButton: {
    primary: string;
  };
  iconButton: {
    background: string;
  };
  input: {
    background: string;
    text: {
      light: string;
    };
    border: {
      default: string;
      focused: string;
    };
  };
  select: {
    cardBackground: string;
    optionBackground: {
      primary: string;
      secondary: string;
    };
  };
  button: {
    disabled: {
      background: string;
      fontColor: string;
    };
    outlined: {
      focusBorder: string;
    };
    contained: {
      hoverBorder: string;
      focusBorder: string;
    };
  };
  switch: {
    checked: {
      track: {
        base: string;
        disabled: string;
        hover: string;
        focus: string;
        border: string;
      };
      thumb: {
        base: string;
        disabled: string;
        hover: string;
        focus: string;
      };
    };
    unchecked: {
      track: {
        base: string;
        disabled: string;
        hover: string;
        focus: string;
        border: string;
      };
      thumb: {
        base: string;
        disabled: string;
        hover: string;
        focus: string;
      };
    };
  };
  tabs: {
    root: string;
    selected: string;
    indicator: string;
    indicatorSpan: string;
    border: string;
  };
  tooltip: {
    background: string;
    border: string;
    color: string;
  };
  menu: {
    background: string;
    border: string;
    item: string;
  };
  mark: {
    background: string;
  };
  reactions: {
    pressed: {
      background: string;
      border: string;
      color: string;
    };
    unpressed: {
      background: string;
      border: string;
      color: string;
    };
    hovered: {
      background: string;
      pressedBackground: string;
    };
  };
  toolbarMessage: {
    boxShadowColor: string;
  };

  scrollBlockGradient: {
    top: string;
    bottom: string;
  };
  linearLoader: {
    line: string;
  };
  fullImageFooterBackground: string;
  badge: {
    success: string;
    secondary: string;
  };
}

export interface IOverridedThemeOptions extends ThemeOptions {
  palette: Omit<ThemeOptions['palette'], 'primary'> & IPalette;
}

const palette: IPalette = {
  mode: 'light',
  common: {
    black: '#000',
    white: '#FFF',
  },
  primary: {
    50: '#FFFAF5',
    100: '#FFF2E5',
    200: '#FFD8C1',
    300: '#FFB8A2',
    400: '#FF9270',
    500: '#234635',
    600: '#1D2F28',
    mainGradient: 'linear-gradient(221.32deg, #FFA15E 5.66%, #FF6636 100%)',
    hoverGradient: 'linear-gradient(221.32deg, #F48737 5.66%, #EE5B2D 100%)',
    contrastText: '#FFF',
    primaryIvory: '#FFFFf1',
  },
  secondary: {
    main: '#404A60',
    light: '#586581',
    dark: '#1D222D',
    contrastText: '#FFFFFF',
  },
  tertiary: {
    primary: '#FF7573',
  },
  status: {
    icon: {
      active: '#60DF43',
      registered: '#FFC530',
      disabled: '#A7AEBF',
    },
    background: {
      active: '#F0F9F2',
      registered: '#FCFAF1',
      disabled: '#EFF3F6',
    },
    text: {
      active: '#1F5619',
      registered: '#936C00',
      disabled: '#586581',
    },
  },
  error: {
    main: '#F3554C',
    light: '#FF8676',
    dark: '#B9131D',
    contrastText: '#F6F9FA',
  },
  warning: {
    main: '#ed6c02',
    light: '#ff9800',
    dark: '#e65100',
    contrastText: '#fff',
  },
  info: {
    main: '#0288d1',
    light: '#03a9f4',
    dark: '#01579b',
    contrastText: '#fff',
  },
  success: {
    main: '#60DF43',
    light: '#4caf50',
    dark: '#1b5e20',
    contrastText: '#fff',
  },
  grey: {
    50: '#1D222D',
    100: '#404A60',
    200: '#586581',
    300: '#727B8C',
    400: '#8A909C',
    500: '#A7AEBF',
    600: '#D4DEE8',
    700: '#616161',
    A100: '#838383',
    A200: '#BDBDBD',
    A300: '#F2F4F6',
    A400: '#f7f7f7',
  },
  white: {
    A1: '#E8EBEE',
    A2: '#EFF3F6',
    A3: '#F6F6F6',
    A4: '#FAFAFA',
    A5: '#FFFFFF',
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  text: {
    primary: '#2D2D2F',
    secondary: '#586581',
    tertiary: '#111727',
    quaternary: '#A7AEBF',
    disabled: '#CDCBD2',
    icon: '',
    gray: '#608175',
    textGreyLight: '#B0BCB8',
  },
  divider: '#E8EBEE',
  background: {
    default: '#FAFAFA',
    paper: '#FFF',
    hover: '#EFF3F7',
    header: '#1976D2',
    footerMenu: '#F2F4F6',
    sidebar: '#F8F8F8',
    secondaryRed: '#F25B5B',
    secondaryRedLight: '#F47E7E',
    secondaryApricot: '#F4B97E',
    secondaryApricotLight: '#FFEAD6',
    secondaryMint: '#B2E2BB',
    secondaryFertile: '#E6F5E1',
    secondaryBlue: '#C1CEEA',
    secondaryBlueLight: '#DDE7FD',
    dark: '#00000014',
    greenLight: '#D3ECCD',
    lightIvoryDark: '#F0F0CD',
    backgroundGreyLight: '#D9E4E1',
    backgroundGreyIvoryLight: '#F8F8E9',
  },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.04)',
    hoverOpacity: 0.04,
    selected: 'rgba(0, 0, 0, 0.08)',
    selectedOpacity: 0.08,
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
    disabledBorder: 'rgba(255, 255, 255, 0.1)',
    disabledOpacity: 0.38,
    focus: 'rgba(0, 0, 0, 0.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
  },
  navButton: {
    primary: '#FF7A50',
  },
  iconButton: {
    background: '#EFF3F7',
  },
  input: {
    background: '#F8F8E9',
    text: {
      light: '#D9E4E1',
    },
    border: {
      default: 'none',
      focused: 'none',
    },
  },
  select: {
    cardBackground: '#D4DEE8',
    optionBackground: {
      primary: '#E8EBEE',
      secondary: '#FAFAFA',
    },
  },
  button: {
    disabled: {
      background: '#D6DBD9',
      fontColor: '#FFF',
    },
    outlined: {
      focusBorder: '0px 0px 0px 1px #234635',
    },
    contained: {
      hoverBorder: '0px 0px 0px 1px #608175',
      focusBorder: '0px 0px 0px 2px #608175',
    },
  },
  switch: {
    checked: {
      track: {
        base: 'linear-gradient(221.32deg, #FFA15E 5.66%, #FF6636 100%)',
        disabled: '#D9D9E2',
        hover: 'linear-gradient(221.32deg, #F48737 5.66%, #EE5B2D 100%)',
        focus: 'linear-gradient(221.32deg, #FFA15E 5.66%, #FF6636 100%)',
        border: '#D4DEE8',
      },
      thumb: {
        base: '#FFFFFF',
        disabled: '#FAFAFA',
        hover: '#FFFFFF',
        focus: '#FFFFFF',
      },
    },
    unchecked: {
      track: {
        base: '#E8EBEE',
        disabled: '#D9D9E2',
        hover: '#D4DEE8',
        focus: '#E8EBEE',
        border: '#D4DEE8',
      },
      thumb: {
        base: '#FFFFFF',
        disabled: '#FAFAFA',
        hover: '#FFFFFF',
        focus: '#FFFFFF',
      },
    },
  },
  tabs: {
    root: '#608175',
    selected: '#1D2F28',
    indicator: '#234635',
    indicatorSpan: '#D4DEE8',
    border: '#E8EBEE',
  },
  tooltip: {
    background: '#1D222D',
    border: '#E8EBEE',
    color: '#E8EBEE',
  },
  menu: {
    background: '#fff',
    border: '#E8EBEE',
    item: '#111727',
  },
  mark: {
    background: '#D4DEE8',
  },
  reactions: {
    pressed: {
      background: '#FFF2E5',
      border: '#FF7A50',
      color: '#FF7A50',
    },
    unpressed: {
      background: '#E8EBEE',
      border: '#FF7A50',
      color: '#586581',
    },
    hovered: {
      background: '#FFF2E5',
      pressedBackground: '#FFD8C1',
    },
  },
  toolbarMessage: {
    boxShadowColor: 'rgba(203, 149, 111, 0.2)',
  },

  scrollBlockGradient: {
    top: 'linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
    bottom: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
  },
  linearLoader: {
    line: 'rgba(64, 74, 96, 0.5)',
  },
  fullImageFooterBackground: 'rgba(0, 0, 0, 0.35)',
  badge: {
    success: '#60DF43',
    secondary: '#8A909C',
  },
};

export type ThemeType = typeof lightThemeObject & IOverridedThemeOptions;

export const lightMaterialTheme: ThemeType = {
  ...lightThemeObject,
  breakpoints: {
    values: lightThemeObject.screen,
  },
  palette,
  typography: {
    htmlFontSize: MAIN_FONT_SIZE,
    fontFamily: 'inherit',
    fontSize: MAIN_FONT_SIZE,
    fontWeightLight: lightThemeObject.font.weight.sm,
    fontWeightRegular: lightThemeObject.font.weight.sm,
    fontWeightMedium: lightThemeObject.font.weight.md,
    fontWeightBold: lightThemeObject.font.weight.lg,
    button: {
      fontFamily: 'inherit',
      fontWeight: lightThemeObject.font.weight.md,
      fontSize: lightThemeObject.font.size.sm,
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textTransform: 'none',
    },
    h1: {
      fontFamily: 'inherit',
      fontWeight: lightThemeObject.font.weight.lg,
      fontSize: lightThemeObject.font.size.xlgSuper,
      lineHeight: '36px',
      letterSpacing: 'normal',
    },
    h2: {
      fontFamily: 'inherit',
      fontWeight: lightThemeObject.font.weight.lg,
      fontSize: lightThemeObject.font.size.xlgSuper,
      lineHeight: '36px',
      letterSpacing: 'normal',
    },
    h3: {
      fontFamily: 'inherit',
      fontWeight: lightThemeObject.font.weight.lg,
      fontSize: lightThemeObject.font.size.xlg,
      lineHeight: '30px',
      letterSpacing: 'normal',
    },
    h4: {
      fontFamily: 'inherit',
      fontWeight: lightThemeObject.font.weight.xs,
      fontSize: lightThemeObject.font.size.mdExt,
      lineHeight: '22px',
      letterSpacing: 'normal',
    },
    h5: {
      fontFamily: 'inherit',
      fontWeight: lightThemeObject.font.weight.lg,
      fontSize: lightThemeObject.font.size.md,
      lineHeight: '24px',
      letterSpacing: 'normal',
    },
    h6: {
      fontFamily: 'inherit',
      fontWeight: lightThemeObject.font.weight.sm,
      fontSize: lightThemeObject.font.size.xs,
      lineHeight: '15.06px',
      letterSpacing: 'normal',
    },
    subtitle1: {
      fontFamily: 'inherit',
      fontWeight: lightThemeObject.font.weight.md,
      fontSize: MAIN_FONT_SIZE,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    subtitle2: {
      fontFamily: 'inherit',
      fontWeight: lightThemeObject.font.weight.md,
      fontSize: MAIN_FONT_SIZE,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    body1: {
      fontFamily: 'inherit',
      fontWeight: lightThemeObject.font.weight.md,
      fontSize: MAIN_FONT_SIZE,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    body2: {
      fontFamily: 'inherit',
      fontWeight: lightThemeObject.font.weight.md,
      fontSize: MAIN_FONT_SIZE,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    caption: {
      fontFamily: 'inherit',
      fontWeight: lightThemeObject.font.weight.md,
      fontSize: MAIN_FONT_SIZE,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    overline: {
      fontFamily: 'inherit',
      fontWeight: lightThemeObject.font.weight.md,
      fontSize: MAIN_FONT_SIZE,
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textTransform: 'uppercase',
    },
  },
  zIndex: {
    mobileStepper: 1,
    appBar: 1,
    drawer: 10,
    modal: 50,
    snackbar: 1,
    tooltip: 50,
    speedDial: 20,
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 36,
          height: 22,
          padding: 0,
          '.MuiTouchRipple-root': {
            opacity: 0,
          },
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: palette.switch.checked.thumb.focus,
              '& + .MuiSwitch-track': {
                background: palette.switch.checked.track.base,
                opacity: 1,
              },
              '& .MuiSwitch-thumb': {
                boxSizing: 'border-box',
                width: 16,
                height: 16,
                color: palette.switch.checked.thumb.base,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                backgroundColor: palette.switch.checked.track.disabled,
              },
              '&.Mui-disabled + .MuiSwitch-thumb': {
                color: palette.switch.checked.thumb.disabled,
              },
              '&.Mui-focusVisible + .MuiSwitch-track': {
                backgroundColor: palette.switch.checked.track.focus,
              },
              '&.Mui-focusVisible + .MuiSwitch-thumb': {
                color: palette.switch.checked.thumb.focus,
              },
            },
            '&.Mui-focusVisible + .MuiSwitch-track': {
              backgroundColor: palette.switch.unchecked.track.focus,
            },
            '&.Mui-focusVisible + .MuiSwitch-thumb': {
              color: palette.switch.unchecked.thumb.focus,
            },
            '&:hover': {
              '&.Mui-checked': {
                '& + .MuiSwitch-track': {
                },
                '& .MuiSwitch-thumb': {
                  color: palette.switch.checked.thumb.hover,
                },
              },
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              backgroundColor: palette.switch.unchecked.track.disabled,
            },
            '&.Mui-disabled + .MuiSwitch-thumb': {
              color: palette.switch.unchecked.thumb.disabled,
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 16,
            height: 16,
            color: palette.switch.unchecked.thumb.base,
          },
          '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            height: '90%',
            background: palette.switch.unchecked.track.base,
            opacity: 1,
            transition: {
              duration: 500,
            },
          },
          '&:hover': {
            '& .MuiSwitch-track': {
              background: palette.switch.unchecked.track.hover,
            },
            '& .MuiSwitch-thumb': {
              color: palette.switch.unchecked.thumb.hover,
            },
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '62px',
          color: palette.tabs.root,
          '& .MuiTabs-indicator': {
            backgroundColor: palette.tabs.indicator,
          },
          '& .MuiTabs-indicatorSpan': {
            width: '100%',
            backgroundColor: palette.tabs.indicatorSpan,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: lightThemeObject.font.size.lg,
          fontWeight: lightThemeObject.font.weight.md,
          color: palette.tabs.root,
          opacity: 1,
          minHeight: '20px',
          padding: '18px 20px',
          borderBottom: '1px solid #D9E4E1',
          '&.Mui-selected': {
            color: palette.tabs.selected,
            fontWeight: lightThemeObject.font.weight.lg,
            padding: '19px 20px',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: '8px 12px',
          background: palette.tooltip.background,
          color: palette.tooltip.color,
          fontSize: lightThemeObject.font.size.sm,
          borderRadius: 8,
          whiteSpace: 'break-spaces',
        },
        arrow: {
          color: palette.tooltip.background,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          background: palette.menu.background,
          border: `1px solid ${palette.menu.border}`,
          borderRadius: '10px',
          boxShadow: 'none',
        },
        list: {
          padding: '10px 10px',
          display: 'flex',
          flexDirection: 'column',
          gap: '2px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: lightThemeObject.font.size.sm,
          fontWeight: lightThemeObject.font.weight.md,
          color: palette.menu.item,
          padding: '4px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          gap: '10px',
          background: '#fff',
          '&:hover': {
            background: '#F0F0CD',
          },
          borderRadius: '4px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: 'inherit',
          padding: '12px 16px',
          borderRadius: '14px',
          color: palette.primary[500],
          '& + .Mui-disabled': {
            borderColor: 'inherit',
            color: palette.button.disabled.fontColor,
          },
          '.MuiTouchRipple-root': {
            opacity: 0,
          },
          fontFamily: 'mulish',
        },
        contained: {
          background: palette.primary[500],
          color: palette.primary.contrastText,
          boxShadow: 'none',

          '&:hover': {
            background: palette.primary[500],
            color: palette.primary.contrastText,
            boxShadow: 'none',
          },
          '&:focus': {
            background: palette.primary[500],
            boxShadow: 'none',
            color: palette.primary.contrastText,
          },
          '&.Mui-disabled': {
            border: `1px solid ${palette.button.disabled.background}`,
            background: palette.button.disabled.background,
            color: palette.button.disabled.fontColor,
          },
        },
        containedError: {
          background: palette.background.secondaryRed,
          border: `1px solid ${palette.background.secondaryRed}`,
          color: palette.error.contrastText,

          '&:hover': {
            background: palette.error.main,
            borderColor: palette.error.main,
          },
          '&:active': {
            background: palette.background.secondaryRed,
            borderColor: palette.background.secondaryRed,
          },
          '&:focus': {
            background: palette.background.secondaryRed,
            borderColor: palette.background.secondaryRed,
          },
          '& + .Mui-disabled': {
            border: `1px solid ${palette.button.disabled.background}`,
            background: palette.button.disabled.background,
            color: palette.button.disabled.fontColor,
          },
        },
        outlined: {
          border: 'none',
          color: palette.primary[600],
          boxShadow: 'none',

          '&:hover': {
            border: 'none',
            borderColor: '#fff',
            color: palette.primary[600],
            background: 'inherit',
            boxShadow: 'none',
          },
          '&:active': {
            border: 'none',
            borderColor: '#fff',
            color: palette.primary[600],
            background: 'inherit',
            boxShadow: 'none',
          },
          '&:focus': {
            border: 'none',
            boxShadow: 'none',
            color: palette.primary[600],
            borderColor: '#fff',
          },
          '& + .Mui-disabled': {
            border: `1px solid ${palette.button.disabled.background}`,
            color: palette.button.disabled.fontColor,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '0px',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          fontSize: `${lightThemeObject.font.size.sm}`,
          color: `${palette.text.gray}`,
          '&.Mui-selected': {
            background: 'none',
            color: `${palette.primary[600]}`,
          },
          '.MuiTouchRipple-root': {
            opacity: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
        },
      },
    },
  },
};

export default createTheme(lightMaterialTheme, lightThemeObject);
