import { createAsyncThunk } from '@reduxjs/toolkit';
import type { IndexDataType } from '../../Checklist/Container';
import learnApi from '../../../../api/learnApi';

export const changeOrder = createAsyncThunk(
  'learn/changeOrder',
  async (data: IndexDataType[]) => {
    const response = await learnApi.updateCategoryOrder({
      learn: data,
    });
    return response;
  },
);
