import Box from '@mui/material/Box';
import styled from 'styled-components';

export default styled(Box)`
  display: flex;
  overflow-y: hidden;
  width: 100%;

  .left-sidebar {
    width: 300px;
    flex-Shrink: 0;
    height: 100vh;
  }
`;
