import type {
  LearnCategoryType,
  ArticleType,
  ArticlePayloadType,
} from 'src/types';
import http from './http';

import type { AxiosResponse } from 'axios';

const learn = '/api/admin/learn';

const createCategory = (data: LearnCategoryType) => {
  return http.post(`${learn}/categories`, data);
};

const getCategories = async () => {
  const response = await http.get<AxiosResponse<LearnCategoryType[]>>(
    `${learn}/categories`,
  );
  return response;
};

const updateCategory = (id: number, data: LearnCategoryType) => {
  return http.post(`${learn}/categories/${id}`, data);
};

const updateItemOrder = async (
  id: number,
  data: { learnItems: { id: number; order: number }[] },
) => {
  const response = await http.patch(
    `${learn}/categories/${id}/items/order`,
    data,
  );
  return response;
};

const updateCategoryOrder = async (data: {
  learn: { id: number; order: number }[];
}) => {
  const response = await http.patch<AxiosResponse<LearnCategoryType[]>>(`${learn}/categories/order`, data);
  return response;
};

const deleteCategory = (id: number) => {
  return http.delete(`${learn}/categories/${id}`);
};

const createArticle = async (id: number, data: ArticlePayloadType) => {
  return http.post(`${learn}/categories/${id}/articles`, data);
};

const getArticles = async (id: number) => {
  const response = await http.get<AxiosResponse<ArticleType[]>>(
    `${learn}/categories/${id}/articles`,
  );
  return response;
};

const getArticle = async (id: number) => {
  const response = await http.get<AxiosResponse<ArticleType[]>>(
    `${learn}/categories/articles/${id}`,
  );
  return response;
};

const updateArticle = (id: number, data: ArticlePayloadType) => {
  return http.post(`${learn}/categories/articles/${id}`, data);
};

const deleteArticle = (id: number) => {
  return http.delete(`${learn}/categories/articles/${id}`);
};

export default {
  createCategory,
  getCategories,
  updateCategory,
  deleteCategory,
  createArticle,
  getArticles,
  getArticle,
  updateArticle,
  deleteArticle,
  updateItemOrder,
  updateCategoryOrder,
};
