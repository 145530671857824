import styled from 'styled-components';

export default styled.div<{ isCurrentAppState: boolean }>`
  .list-item-button {
    padding: 12px 18px;
    display: flex;
    gap: 10px;
    width: 240px;
    color: ${({ isCurrentAppState, theme }) => (isCurrentAppState
    ? theme.palette.primary[600]
    : theme.palette.text.gray)};
    font-weight: ${({ theme }) => theme.font.weight.xs};
    font-size: ${({ theme }) => theme.font.size.lg};
    background-color: ${({ isCurrentAppState, theme }) => (isCurrentAppState ? theme.palette.background.lightIvoryDark : 'unset')};
    border-radius: ${({ isCurrentAppState }) => (isCurrentAppState ? '10px' : '0px')};
    border: ${({ isCurrentAppState, theme }) => (isCurrentAppState ? `2px solid ${theme.palette.primary[500]}` : '2px solid transparent')};
    &:hover {
      background-color: ${({ isCurrentAppState, theme }) => (isCurrentAppState ? theme.palette.background.lightIvoryDark : 'unset')};
      border-radius: 10px;
    }
  }
`;
