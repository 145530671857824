import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    outline: none;
    list-style: none;
    font-family: inherit;
    color: inherit;
    -webkit-tap-highlight-color: rgba(22, 36, 29, 0.4);

    ::-webkit-scrollbar-track {
      background: inherit;
    }
    ::-webkit-scrollbar-thumb {
      background: #FFFFFF;
      border-radius: 8px;

    }
  }

  a {
    text-decoration: none;
  }

  input {
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    :-webkit-autofill,
    :-webkit-autofill:hover, 
    :-webkit-autofill:focus {
      box-shadow: 0 0 0 30px #FFFFFF inset;
      -webkit-text-fill-color: #000000;
    }
  }

  *::selection {
    background-color: rgba(212, 236, 205, 1);
    color: rgba(29, 47, 40, 1);
  }

  html {
    font-family: Mulish;
    font-size:  16px;
    font-weight: 700;
    color: rgba(29, 47, 40, 1);
    background-color: #FFFFFF;
  }

  html,
  body,
  #root {
    min-height: 100%;
    height: 1px;
  }

  mark {
    background-color: rgba(35, 70, 53, 1);
    border-radius: 2px;
  }

  #root {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
  }

  .cursor-pointer {
    cursor: pointer;
  }
`;
