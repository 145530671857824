import styled from 'styled-components';

import PageContainer from 'src/ui/components/PageContainer';

type StyledPropsType = {
  isSignUp?: boolean;
  hideTitle?: boolean;
};

export default styled(PageContainer)<StyledPropsType>`
display: flex;
background: ${({ theme }) => theme.palette.background.paper};
padding: 0px;

  .auth__promo {
    position: relative;
    width: 43%;
    background-color: ${({ theme }) => theme.palette.background.secondaryApricot};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 60px;
    overflow: hidden;
  }

  .logo-star {
    position: absolute;
    top: -50px;
    right: -50px;
  }

  .logo-lines {
    position: absolute;
    bottom: -50px;
    left: -50px;
  }

  .mobile-logo {
    z-index: 100;
  }

  .auth__content {
    width: 57%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;
  }

  .auth__title {
    padding-top: 27px;
  }
`;
