import store, { resetStore } from 'src/store';
import storage from './storage';
import authApi from 'src/api/authApi';

const safeJsonParse = <R = unknown>(string: string, fallbackValue?: R | null): R | null => {
  try {
    return JSON.parse(string) as R;
  } catch {
    return fallbackValue || null;
  }
};

const sleep = (timeout: number) => new Promise((res) => { setTimeout(res, timeout); });

const logOut = () => {
  store.dispatch(resetStore());
  storage.authToken.remove();
  storage.refreshToken.remove();
  window.location.replace('/');
};

const setTokens = (authToken: string, refreshToken: string) => {
  storage.authToken.set(authToken);
  storage.refreshToken.set(refreshToken);
};

const refreshToken = async () => {
  const refreshToken = storage.refreshToken.get();
  if (!refreshToken) {
    logOut();
    return false;
  }
  try {
    const { data } = await authApi.getTokens({ refreshToken });
    setTokens(data.token, data.refreshToken);

    return true;
  } catch (err) {
    logOut();
    return false;
  }
};

export default {
  safeJsonParse,
  sleep,
  logOut,
  setTokens,
  refreshToken,
};
