import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import Sidebar from '../Sidebar';

import StyledMainLayout from './MainLayout.styles';

const MainLayout = () => {
  return (
    <StyledMainLayout>
      <Box
        className="left-sidebar"
        component="nav"
      >
        <Sidebar />
      </Box>
      <Outlet />
    </StyledMainLayout>
  );
};

export default MainLayout;
