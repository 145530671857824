import styled from 'styled-components';

import Button from '@mui/material/Button';

export default styled(Button)<{ $btnSize: 'lg' | 'md' }>`
  height: 43px;
  padding: ${(props) => (props.$btnSize === 'lg' ? '16px 16px' : '12px 16px')};
  font-size: 16px;

  .button__loader-icon {
    height: 2em;
  }
`;
