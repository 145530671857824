import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { ChecklistItemType, ChecklistCategoryType } from 'src/types';
import { changeOrder } from '../../ui/pages/Checklist/thunks';
import { toast } from 'react-toastify';

const getInitialStore = () => ({
  categories: [] as ChecklistCategoryType[],
});

const checklistSlice = createSlice({
  name: 'checklist',
  initialState: getInitialStore,
  reducers: {
    addCategory: (store, { payload }: PayloadAction<ChecklistCategoryType>) => {
      store.categories.push(payload);
    },
    setCategories: (store, { payload }: PayloadAction<{ data: ChecklistCategoryType[] }>) => {
      store.categories = payload.data;
    },
    removeCategory: (store, { payload }: PayloadAction<number>) => {
      store.categories = store.categories.filter((category) => category.id !== payload);
    },
    updateCategoryTitle: (store, { payload }: PayloadAction<{ id: number; data: string }>) => {
      const res = store.categories.find((category) => category.id === payload.id);
      if (res && res.title) {
        res.title = payload.data;
      }
    },
    updateCategoryImage: (store, { payload }: PayloadAction<{ id: number; data: string }>) => {
      const res = store.categories.find((category) => category.id === payload.id);
      if (res) {
        res.image = payload.data;
      }
    },

    addChecklistItem: (
      store,
      { payload }: PayloadAction<{ id: number; checklistItem: ChecklistItemType }>,
    ) => {
      const currentCategory = store.categories.find((category) => category.id === payload.id);
      if (!currentCategory) {
        return;
      }
      if (currentCategory.items) {
        currentCategory.items.push(payload.checklistItem);
      } else {
        currentCategory.items = [payload.checklistItem];
      }
    },
    setChecklistItems: (
      store,
      { payload }: PayloadAction<{ id: number; checklist: ChecklistItemType[] }>,
    ) => {
      store.categories.forEach((category) => {
        if (category.id === payload.id) {
          // eslint-disable-next-line no-param-reassign
          category.items = payload.checklist;
        }
      });
    },
    removeChecklistItem: (
      store,
      { payload }: PayloadAction<{ id: number; checklistItemId: number }>,
    ) => {
      store.categories.forEach((category) => {
        if (category.id === payload.id) {
          // eslint-disable-next-line no-param-reassign
          category.items = category.items?.filter(
            (item) => item.id !== payload.checklistItemId,
          );
        }
      });
    },
    updateChecklistItem: (
      store,
      { payload }: PayloadAction<{ id: number; data: ChecklistItemType }>,
    ) => {
      store.categories.forEach((category) => {
        if (category.id === payload.id) {
          if (category.items) {
            // eslint-disable-next-line no-param-reassign
            category.items = [
              ...category.items.filter((item) => item.id === payload.data.id),
              payload.data,
            ];
          } else {
            // eslint-disable-next-line no-param-reassign
            category.items = [payload.data];
          }
        }
      });
    },
    resetChecklists: (store) => {
      store.categories = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(changeOrder.fulfilled, (store, { payload }) => {
      store.categories = payload.data || [];
    });
    builder.addCase(changeOrder.rejected, () => {
      toast.error('change order error');
    });
  },
});

export const checklistSliceActions = checklistSlice.actions;

export default checklistSlice.reducer;
